@import '../../utils/variables';
$control-size: 5.5rem;
$control-size-sm: 3.5rem;

.slidersukcesu.slidersukcesu--team {
	.carousel-indicators {
		position: static;
		margin-bottom: 0;
		margin-top: $sp-paragraph;
	}

	.carousel-control-prev,
	.carousel-control-next {
		position: absolute;
		top: calc(-10rem - #{$h1-font-size});
		width: $control-size;
		height: $control-size;
		border-radius: 50%;
		border: 1px solid #fff;
		opacity: 1;
		&:hover {
			background: #fff;
			color: $primary;
		}
		@media (max-width: 991.98px) { 
			top: -10rem;
		}
		@media (max-width: 767.98px) {
			top: -5rem;
			width: $control-size-sm;
			height: $control-size-sm;
		}
		.material-icons {
			@media (max-width: 767.98px) {
				font-size: 2.4rem;
			}
		}
	}

	.carousel-control-next {
		@media (max-width: 767.98px) {
			left: calc(#{$control-size-sm} + 2rem);
		}
		@media (max-width: 575.98px) {
			left: calc(50% - #{$control-size-sm} + 3rem);
			transform: translateX(50%);
		}
	}

	.carousel-control-prev {
		left: calc(100% - 2*#{$control-size} - 2rem);
		@media (max-width: 767.98px) {
			left: 0;
		}
		@media (max-width: 575.98px) {
			left: calc(50% - #{$control-size-sm} - 3rem);
			transform: translateX(50%);
		}
	}
}

.team {
	text-align: center;
	margin-bottom: #{-$sp-paragraph};
	&__person {
		position: relative;
		margin-bottom: $sp-paragraph;
		img {
			border-radius: 50%;
			@media (max-width: 575.98px) {
				padding: .75rem;
				max-height: 15rem;
			}
		}
	}
}

#team-slider {
	padding-bottom: 6rem;
	&.full-background {
		&::before {
			background-image: url("../../../../../images/strona-glowna/poznaj-nasz-zespol/poznaj-nasz-zespol.jpg");
		}
	}

}
